import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

new Swiper('.slider', {
  modules: [Navigation],
  slidesPerView: 'auto',
  spaceBetween: 20,
  navigation: {
    prevEl: '.game-slider-arrs-prev',
    nextEl: '.game-slider-arrs-next',
  },
});

new Swiper('.news-slider', {
  modules: [Pagination],
  slidesPerView: 1,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    728: {
      slidesPerView: 1,
    },
    984: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});

if (window.innerWidth <= 1200) {
  $('.hero-slot-slider .hero-slot-items').addClass('swiper-wrapper');

  new Swiper('.hero-slot-slider', {
    modules: [Pagination],
    slidesPerView: 'auto',
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      // 728: {
      //   slidesPerView: 1,
      // },
      // 984: {
      //   slidesPerView: 2,
      // },
      // 1200: {
      //   slidesPerView: 4,
      //   spaceBetween: 40,
      // },
    },
  });
} else {
  //не выполнять
}
